const logParams = new Map([
  [30300, {
    event_type: "10000~50000",
    event_name: 30300,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐",
      str3: "展示",
      str4: "show",
    },
  }],
  [30301, {
    event_type: "10000~50000",
    event_name: 30301,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐-收起",
      str3: "点击",
      str4: "click",
    },
  }],
  [30302, {
    event_type: "10000~50000",
    event_name: 30302,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐-换一批",
      str3: "点击",
      str4: "click",
    },
  }],
  [30303, {
    event_type: "10000~50000",
    event_name: 30303,
    event_data: {
      str1: "搜索页",
      str2: "智能推荐-点歌",
      str3: "点击",
      str4: "click",
    },
  }],
  [30304, {
    event_type: "10000~50000",
    event_name: 30304,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗",
      str3: "展示",
      str4: "show",
    },
  }],
  [30305, {
    event_type: "10000~50000",
    event_name: 30305,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗-取消",
      str3: "点击",
      str4: "click",
    },
  }],
  [30306, {
    event_type: "10000~50000",
    event_name: 30306,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗-减频确定",
      str3: "点击",
      str4: "click",
    },
  }],
  [30307, {
    event_type: "10000~50000",
    event_name: 30307,
    event_data: {
      str1: "歌星页", // 搜索页/
      str2: "智能推荐-设置弹窗-从不确定",
      str3: "点击",
      str4: "click",
    },
  }],
  [30308, {
    event_type: "10000~50000",
    event_name: 30308,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐",
      str3: "展示",
      str4: "show",
    },
  }],
  [30309, {
    event_type: "10000~50000",
    event_name: 30309,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐-收起",
      str3: "点击",
      str4: "click",
    },
  }],
  [30310, {
    event_type: "10000~50000",
    event_name: 30310,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐-换一批",
      str3: "点击",
      str4: "click",
    },
  }],
  [30311, {
    event_type: "10000~50000",
    event_name: 30311,
    event_data: {
      str1: "歌星页",
      str2: "智能推荐-点歌",
      str3: "点击",
      str4: "click",
    },
  }]
]);

export default logParams;