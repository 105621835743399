<template>
  <div class="lb-rs-sidebar" :class="{ 'is-active': isActive }">
    <div class="lb-rs-sidebar-header">
      <button @click.stop="toggleSidebar" class="close-btn">
        <span v-if="!isHasControl" class="close-btn-time">{{ closeTime }}s</span>
        收起
        <img
          src="https://qncweb.ktvsky.com/20240822/vadd/389de39cc490e4db809e7d098c7e009d.png"
          alt=""
        />
      </button>
      <div class="lb-rs-sidebar-header-title">
        {{ rcTitle }}
      </div>
      <span class="refresh-btn" @click.stop="refresh">
        <img
          src="https://qncweb.ktvsky.com/20240927/vadd/e989a02510e7266ecf8e46df1601a9bf.png"
          alt=""
        />
        换一批
      </span>
    </div>
    <div class="lb-rs-sidebar-content" v-if="songList.length">
      <template v-for="(song, i) in songList" :key="i">
        <SongItem
          isShowPlayBtn
          isShowOrderBtn
          renderType="block"
          :isStickButton="false"
          class="recommend-list-item"
          :songItem="song"
          :log-from="{
            fr1: from === 'search' ? 1894 : 1896,
            fr2: from === 'search' ? '搜索-智能推荐' : '歌星-智能推荐',
          }"
          @singer-click="handleClickSinger"
          @order-click="handleOrderAction"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed, toRefs, onMounted, onActivated, watch } from "vue";
import { useStore } from "vuex";
import SongItem from "@/components/song-item";
// import useRecommend from "@/composables/useRecommend";
// import {
//   setCloseRecommendTag,
//   getCloseRecommendTag,
// } from "@/utils/historyCache";
import { sendLog } from "@/directives/v-log/log";
import logParams from "@/log";

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    rcTitle: {
      type: String,
      default: '特斯拉车主最常点的歌，总有一首适合你!'
    },
    from: {
      type: String,
      default: 'singer'
    }
  },
  components: {
    SongItem
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { isActive, from } = toRefs(props);
    const store = useStore();
    // const { showRecommendConfirm } = useRecommend();
    let isHasControl = ref(false);

    const songLists = computed(() => {
      if (from.value === 'singer') {
        return store.state.recommend.recommendTop100
      }
      return store.state.oftenSing.oftenSingList.concat(store.state.recommend.recommendTop100)
    });
    // const oftenSongs = computed(() => store.state.recommend.oftenSongs);

    let songList = ref([])
    let refeshPage = ref(0)
    let size = ref(4)

    let closeTime = ref(30)
    let closeTimer = null

    const refresh = (defaultRefresh = true) => {
      console.log('refresh', defaultRefresh)
      if (defaultRefresh) {
        console.log('refresh')
        stopCloseTimer()
        isHasControl.value = true
        sendLog(logParams.get(from.value === 'search' ? 30302 : 30310))
      }

      // refresh
      songList.value = songLists.value.slice(refeshPage.value * size.value, (refeshPage.value + 1) * size.value)
      refeshPage.value++
      // console.log(songLists.value, 'songLists')
      // console.log(songList.value, 'songList')
      if (songList.value.length === 0 && songLists.value.length > 0) {
        refeshPage.value = 0
        refresh()
      }
    }

    const handleOrderAction = (v) => {
      console.log(v);
      stopCloseTimer()
      isHasControl.value = true
      // sendLog({
      //   event_type: "10000~50000",
      //   event_name: 30260,
      //   event_data: {
      //     str1: "首页",
      //     str2: "智能推荐-点歌",
      //     str3: "点击",
      //     str4: "click",
      //   },
      // });
      sendLog(logParams.get(from.value === 'search' ? 30303 : 30311))
    };

    const toggleSidebar = () => {
      sendLog(logParams.get(from.value === 'search' ? 30301 : 30309));
      emit("close", isHasControl.value);
      // if (isHasControl.value) return;
      // const closeTag = getCloseRecommendTag();
      // if (closeTag > 2) return;
      // if (closeTag === 2) {
      //   //3次无操作
      //   showRecommendConfirm();
      //   sendLog({
      //     event_type: "10000~50000",
      //     event_name: 30262,
      //     event_data: {
      //       str1: "首页",
      //       str2: "智能推荐-设置弹窗",
      //       str3: "展示",
      //       str4: "show",
      //     },
      //   });
      // }
      // setCloseRecommendTag(closeTag + 1);
    };

    const handleClickSinger = () => {};

    // watch(songLists, () => {
    //   refresh()
    //   isHasControl.value = false
    // })

    const reduceCloseTime = () => {
      closeTime.value--
      if (closeTime.value === 0) {
        emit("close", isHasControl.value)
        stopCloseTimer()
      }
    }

    const startCloseTimer = () => {
      isHasControl.value = false
      closeTime.value = 30
      stopCloseTimer()
      closeTimer = setInterval(reduceCloseTime, 1000)
    }

    const stopCloseTimer = () => {
      if (closeTimer) clearInterval(closeTimer)
    }

    // onMounted(() => {
    //   refresh(true)
    //   startCloseTimer()
    // })

    // onActivated(() => {
    //   refresh(false)
    //   startCloseTimer()
    // })

    watch(isActive, (val) => {
      if (val) {
        refresh(false)
        startCloseTimer()
      }
    })
    
    return {
      songList,
      closeTime,
      toggleSidebar,
      refresh,
      isHasControl,
      handleClickSinger,
      handleOrderAction,
    };
  },
};
</script>

<style lang="stylus" scoped>
.lb-rs-sidebar
  position fixed
  // bottom 398px
  bottom 0
  left 0
  right 0
  // z-index 111
  z-index 0
  width 958px
  height 398px
  padding 10px 24px 0 24px
  // background rgba(0, 0, 0, 0.5)
  // box-shadow 0px -2px 20px 0px rgba(0, 0, 0, 0.25)
  background url(https://qncweb.ktvsky.com/20240927/vadd/0f9d1654a871b283bc399c7b862d4788.png)
  background-size cover
  background-position bottom
  background-repeat no-repeat
  transition transform 0.3s ease
  transform translateY(100%)
  &.is-active
    transform translateY(0)
  &-header
    display flex
    justify-content space-between
    align-items center
    padding 0
    width 910px
    height 54px
    margin-bottom 9px
    .close-btn
      flex 0 0 auto
      width 158px
      height 38px
      display flex
      align-items center
      font-size 28px
      color rgba(255, 255, 255, 1)
      .close-btn-time
        width 56px
        height 38px
        margin-left 0px
        font-size 20px
        color rgba(255, 255, 255, 0.8)
        background rgba(241, 241, 241, 0.2)
        border-radius 34px
        display flex
        align-items center
        justify-content center
        margin-right 8px
      img
        width 32px
        height 32px
        margin-left 6px
    .lb-rs-sidebar-header-title
      flex 1
      font-size 24px
      color rgba(219, 174, 106, 1)
      text-align center
    .refresh-btn
      flex 0 0 auto
      display flex
      align-items center
      font-size 28px
      color rgba(255, 255, 255, 1)
      font-weight 400
      width 158px
      height 33px
      img
        width 32px
        height 32px
        margin-right 12px
  &-content
    // overflow hidden
    width 100%
    height 294px
    padding 0 2px
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-template-rows repeat(2, 1fr)
    gap 24px
    .recommend-list-item
      width 442px
      height 135px
      ::v-deep .song-block
        width 442px
        height 135px
        margin-bottom 0
        .name
          max-width 230px
  @media screen and (max-width 1200px) and (min-height 1200px)
    display none
</style>
